class HighlightConverter {
    constructor({ win = window, doc = document, context, baseUri = "" } = {}) {
        this.win = win;
        this.doc = doc;
        this.context = context || doc;
        this.baseUri = baseUri;

        if (!Range.prototype.intersectsNode) {
            Range.prototype.intersectsNode = function (node) {
                let range = document.createRange();
                range.selectNode(node);

                return (
                    0 > this.compareBoundaryPoints(Range.END_TO_START, range) &&
                    0 < this.compareBoundaryPoints(Range.START_TO_END, range)
                );
            };
        }

        this.elements = Array.from(this.context.querySelectorAll("[data-aid]"));
        this.wordBoundryRegex =
            /\s+|[\u002D\u058A\u05BE\u1400\u1806\u2010-\u2015\u2053\u207B\u208B\u2212\u2E17\u2E1A\u2E3A-\u301C\u3030\u30A0\uFE31\uFE32\uFE58\uFE63\uFF0D]/g;
    }

    /********************************************************************************
     * These are modified portions of the IOS teams selection.js
     * https://github.com/LDSChurch/GospelLibrary-iOS/blob/master/GospelLibrary/selection.js
     ********************************************************************************/
    getHighlightFromRange(range) {
        range = this.normalizeRange(range);

        const startContainer = range.startContainer,
            startCharacterOffset = range.startOffset,
            elements = this.elements.filter((elementWithAID) =>
                range.intersectsNode(elementWithAID)
            );

        if (elements.length === 0) {
            if (range.commonAncestorContainer.dataset.aid) {
                elements.push(range.commonAncestorContainer);
            } else {
                const closestWithAID = this._closestAID(
                    range.commonAncestorContainer
                );

                closestWithAID && elements.push(closestWithAID);
            }
        }

        if (elements.length === 0) return;

        let startElement = this._closestAID(startContainer);

        let endContainer = range.endContainer,
            endCharacterOffset = range.endOffset,
            endElement = this._closestAID(endContainer);

        return elements.reduce((wordOffsets, element) => {
            const pid = element.dataset.aid.toString();
            const uri = `${this.baseUri}.${element.id}`;

            let startOffset = -1,
                endOffset = -1;

            if (element === startElement) {
                startOffset = this._getWordOffset(
                    startContainer,
                    startCharacterOffset,
                    startElement,
                    true
                );
            }

            if (element === endElement) {
                endOffset = this._getWordOffset(
                    endContainer,
                    endCharacterOffset,
                    endElement,
                    false
                );
            }

            // no text is actually selected for the paragraph - don't include these offsets
            if (startOffset === -1 && endOffset === 0) {
                return wordOffsets;
            }

            // don't allow the startOffset to be larger than the endOffset - setting them to the same
            // value makes a highlight of one word, which the user can still find.
            if (endOffset !== -1) {
                startOffset = Math.min(startOffset, endOffset);
            }

            return [...wordOffsets, { pid, uri, startOffset, endOffset }];
        }, []);
    }

    // text, comment, and cdata section nodes all count as "text" nodes to ranges
    isTextNode(node) {
        return [
            Node.TEXT_NODE,
            Node.CDATA_SECTION_NODE,
            Node.COMMENT_NODE,
        ].includes(node.nodeType);
    }

    normalizeStartBoundary(container, offset) {
        // An orphaned start is when the range begins at the very end of the start container.
        // Range boundaries represented by brackets []: <p>previous text[</p><p>selected text]</p>
        const isOrphanedStart = this.isTextNode(container)
            ? offset === container.textContent.length
            : offset === container.childNodes.length;

        if (isOrphanedStart) {
            if (container.nextSibling) {
                container = container.nextSibling;
                offset = 0;
            }
        }

        if (!this.isTextNode(container)) {
            const walker = this.doc.createTreeWalker(
                container.childNodes[offset],
                NodeFilter.SHOW_TEXT
            );

            walker.firstChild();

            container = walker.currentNode;
            offset = 0;
        }

        return { container, offset };
    }

    normalizeEndBoundary(container, offset) {
        // An orphaned end is when the range ends just after the end container begins.
        // Range boundaries represented by brackets []: <p>[selected text</p><p>]next text</p>
        const isOrphanedEnd = offset === 0;

        if (isOrphanedEnd) {
            container = container.previousSibling;
            offset = this.isTextNode(container)
                ? (offset = container.textContent.length)
                : (offset = container.childNodes.length);
        }

        if (!this.isTextNode(container)) {
            const walker = this.doc.createTreeWalker(
                container.childNodes[offset - 1],
                NodeFilter.SHOW_TEXT
            );

            walker.lastChild();

            container = walker.currentNode;
            offset = walker.currentNode.textContent.length || 0;
        }

        return { container, offset };
    }

    // Normalizes a range to ensure that the start and end boundaries are text nodes and not elements.
    // Ranges treat the offset for text nodes and elements differently and the word counting algorithm
    // only handles the offset method for text nodes.
    normalizeRange({ startContainer, startOffset, endContainer, endOffset }) {
        let startBoundary = this.normalizeStartBoundary(
            startContainer,
            startOffset
        );
        let endBoundary = this.normalizeEndBoundary(endContainer, endOffset);

        const range = document.createRange();

        range.setStart(startBoundary.container, startBoundary.offset);
        range.setEnd(endBoundary.container, endBoundary.offset);

        return range;
    }

    getRangeFromHighlight(highlight) {
        const el = this.context.querySelector(
            `[data-aid="${highlight && highlight.pid}"]`
        );

        if (!el) return;

        const { container: startContainer, offset: startOffset } =
            this._getContainerAndOffset({
                el,
                wordOffset: highlight.startOffset,
                isStart: true,
            });

        const { container: endContainer, offset: endOffset } =
            this._getContainerAndOffset({
                el,
                wordOffset: highlight.endOffset,
            });

        return this.buildRange({
            startContainer,
            endContainer,
            startOffset,
            endOffset,
        });
    }

    _closestAID(node, selector = "[data-aid]") {
        return node && node.closest
            ? node.closest(selector)
            : node.parentElement.closest(selector);
    }

    _getWordOffset(container, offset, element, isStart) {
        let foundWordOffset = false,
            totalWords = 0,
            wordOffset = 0,
            currentOffset = 0,
            words;

        const countOffsets = (word) => {
            const wordLength = word.length;
            word = word.trim();

            // Empty string
            if (word.length === 0) {
                currentOffset += wordLength;

                return;
            }

            // Add to our total count of words
            totalWords++;

            // Already found the word, so just keep looping until we get the totalWords count
            if (foundWordOffset) return;

            // We haven't found the word yet, so increment our word offset
            wordOffset++;

            // Continue to the next word if it's not in the container.  However, sometimes
            // no words are found in the container (i.e. the container is an just a text
            // node with only whitespace) and will never find the first/last word if we don't
            // check for this.
            if (treeWalker.currentNode !== container) {
                const curentRelativePosition =
                    container.compareDocumentPosition(treeWalker.currentNode);
                const beforeContainer =
                    curentRelativePosition & Node.DOCUMENT_POSITION_PRECEDING;
                const afterContainer =
                    curentRelativePosition & Node.DOCUMENT_POSITION_FOLLOWING;

                // skip the node since we haven't reached the container yet.
                if (beforeContainer) return;

                // we missed the container - go back one word if we're looking for the endOffset
                if (!isStart && afterContainer) wordOffset--;
            }

            const plusSpace = words.length > 1;
            const nextOffset = currentOffset + wordLength + plusSpace;

            foundWordOffset =
                (isStart && offset < nextOffset) ||
                (!isStart && offset <= nextOffset);

            currentOffset += wordLength + plusSpace;
        };

        const treeWalker = this.doc.createTreeWalker(
            element,
            NodeFilter.SHOW_TEXT
        );

        while (treeWalker.nextNode()) {
            // Reset, new node
            currentOffset = 0;

            words = treeWalker.currentNode.nodeValue.split(
                this.wordBoundryRegex
            );

            words.forEach(countOffsets);
        }

        // Start or end of paragraph
        if (
            (isStart && wordOffset === 1) ||
            (!isStart && wordOffset === totalWords)
        )
            wordOffset = -1;

        if (isStart && wordOffset > totalWords) wordOffset = totalWords;

        return wordOffset;
    }

    _getContainerAndOffset({ el, wordOffset, isStart }) {
        let currentWordOffset = 0;
        let maxWordOffset = this._getMaxWordOffset(el);
        wordOffset = parseInt(wordOffset, 10);
        wordOffset = wordOffset >= maxWordOffset ? maxWordOffset : wordOffset;

        if (isStart) {
            if (maxWordOffset === 0) {
                return { container: el, offset: 0 };
            } else if (wordOffset <= -1 || wordOffset === 1) {
                return { container: this._getFirstTextNode(el), offset: 0 };
            }
        } else {
            if (maxWordOffset === 0) {
                return { container: el, offset: el.children.length };
            } else if (wordOffset === 0) {
                return { container: this._getFirstTextNode(el), offset: 0 };
            } else if (wordOffset === -1) {
                wordOffset = maxWordOffset;
            }
        }

        const treeWalker = this.doc.createTreeWalker(el, NodeFilter.SHOW_TEXT);

        while (treeWalker.nextNode()) {
            const words = treeWalker.currentNode.nodeValue.split(
                this.wordBoundryRegex
            );

            let offset = 0;
            let length = words.length;

            for (let i = 0; i < length; i++) {
                let word = words[i];

                var charOffsetOfWord = word.length;

                // Add +1 for the space if more than 1 word in node, and its not the last word
                if (length > 1 && i !== length - 1) charOffsetOfWord++;

                offset += charOffsetOfWord;

                word = word.trim();

                // No word
                if (word.length === 0) continue;

                currentWordOffset++;

                if (wordOffset === currentWordOffset) {
                    if (isStart) {
                        // Offset should be at the start of this word, not the end.
                        offset -= charOffsetOfWord;
                    } else if (length > 1 && i !== length - 1) {
                        offset--;
                    }

                    return { container: treeWalker.currentNode, offset };
                }
            }
        }
    }

    _getMaxWordOffset(el) {
        let maxWordOffset = 0;

        const treeWalker = this.doc.createTreeWalker(el, NodeFilter.SHOW_TEXT);

        while (treeWalker.nextNode()) {
            let words = treeWalker.currentNode.nodeValue.split(
                this.wordBoundryRegex
            );
            maxWordOffset += words.reduce(
                (numWords, word) => numWords + !!word.trim().length,
                0
            );
        }

        return maxWordOffset;
    }

    _getFirstTextNode(node) {
        const treeWalker = this.doc.createTreeWalker(
            node,
            NodeFilter.SHOW_TEXT,
            {
                acceptNode: (node) =>
                    node.nodeValue.trim()
                        ? NodeFilter.FILTER_ACCEPT
                        : NodeFilter.FILTER_REJECT,
            }
        );

        return treeWalker.nextNode();
    }

    /********************************************************************************
     * End of code based on selection.js from the mobile team
     ********************************************************************************/

    getRangeFromHighlights(highlights = []) {
        const startRange = this.getRangeFromHighlight(highlights[0]);
        const endRange = this.getRangeFromHighlight(
            highlights[highlights.length - 1]
        );

        return this.buildRange({
            startContainer: startRange.startContainer,
            startOffset: startRange.startOffset,
            endContainer: endRange.endContainer,
            endOffset: endRange.endOffset,
        });
    }

    addMark(range, highlight, first) {
        if (range.startContainer.parentElement.localName === "sup")
            range.setStartBefore(range.startContainer.parentElement);

        if (range.endContainer.parentElement.localName === "sup")
            range.setEndAfter(range.endContainer.parentElement);

        const newMark = this.doc.createElement("mark");

        const {
            // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...attribs
            style,
            ...attribs
        } = highlight;

        delete attribs.first;

        Object.entries(attribs).forEach(([key, value]) =>
            newMark.setAttribute(key, value)
        );

        highlight.style && newMark.setAttribute("underline", "underline");
        highlight.first && first && newMark.setAttribute("first", "first");
        range.surroundContents(newMark);
    }

    buildRange({ startContainer, endContainer, startOffset, endOffset }) {
        const range = new Range();

        if (
            startContainer === endContainer &&
            !startOffset &&
            (endOffset === undefined ||
                endOffset ===
                    (endContainer.data || endContainer.childNodes).length)
        ) {
            range.selectNodeContents(startContainer);
        } else {
            startOffset !== undefined
                ? range.setStart(startContainer, startOffset)
                : range.setStartBefore(startContainer);
            endOffset !== undefined
                ? range.setEnd(endContainer, endOffset)
                : range.setEndAfter(endContainer);
        }

        return range;
    }

    safeAddMark(highlight) {
        const range = this.getRangeFromHighlight(highlight);

        if (!range) return;

        // The range will fully encompass any sibling nodes so no splitting should be needed
        if (
            range.startContainer.parentElement ===
            range.endContainer.parentElement
        )
            return this.addMark(range, highlight, true);

        const iterator = this.doc.createTreeWalker(
            range.commonAncestorContainer,
            NodeFilter.SHOW_TEXT,
            {
                acceptNode: (node) =>
                    range.intersectsNode(node) && node.data.length
                        ? NodeFilter.FILTER_ACCEPT
                        : NodeFilter.Filter_REJECT,
            }
        );

        // setting up initial range values
        let startOffset = range.startOffset,
            currentNode,
            endOffset,
            ranges = [];

        // find the next starting and ending nodes
        while (iterator.nextNode()) {
            currentNode = iterator.currentNode;

            // if the current node is the final node then set the end offset;
            if (currentNode === range.endContainer) {
                endOffset = range.endOffset;
            }

            // build the next range
            ranges.push(
                this.buildRange({
                    startContainer: currentNode,
                    startOffset,
                    endContainer: currentNode,
                    endOffset,
                })
            );

            // unset startOffset, so it does not get used for the next range
            startOffset = undefined;
        }

        // add all of the split ranges
        ranges.forEach((range, i) => this.addMark(range, highlight, i === 0));

        return true;
    }
}

export default HighlightConverter;
